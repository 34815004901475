import React, { useState } from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook, FaLinkedinIn} from "react-icons/fa"
import {HiOutlineMail} from "react-icons/hi"
import {BsFillPersonLinesFill} from "react-icons/bs"
import Logo from "../assets/logo1.png"
import {Link} from "react-scroll"

const Navbar = () => {
const [nav, setNav] = useState(false)
const handleClick = () => setNav(!nav)
  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
        <div>
            <img src={Logo} alt="Logo Image" style={{width: '100px'}} />
        </div>

        {/* Menu */}

        <ul className='hidden md:flex gap-4 hover:cursor-pointer'>
            <li>
                <Link to='home' smooth={true} duration={500} >
                  Acceuil
                </Link>
            </li>
            <li>
                <Link to='about' smooth={true} duration={500} >
                  A propos
                </Link>
            </li>
            <li>
                <Link to='skills' smooth={true} duration={500} >
                  Skills
                </Link>
            </li>
            <li>
                <Link to='work' smooth={true} duration={500} >
                  Projets
                </Link>
            </li>
            <li>
                <Link to='contact' smooth={true} duration={500} >
                  Contact
                </Link>
            </li>
        </ul>

      {/* Hamburger */}

      <div onClick={handleClick} className="md:hidden z-10 hover:cursor-pointer">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}

      <ul className={!nav ? "hidden" : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center" }>
        <li className='py-6 text-4xl hover:cursor-pointer'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Acceuil
          </Link>
        </li>
        <li className='py-6 text-4xl hover:cursor-pointer'>
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            A propos
          </Link>
        </li>
        <li className='py-6 text-4xl hover:cursor-pointer'>
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-4xl hover:cursor-pointer'>
          <Link onClick={handleClick} to='work' smooth={true} duration={500}>
            Projets
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Social icons */}

      <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
            <a className=' p-4 flex justify-between items-center w-full text-gray-300' href="https://www.linkedin.com/in/kassim-bouzoubaa/" target="_blank">
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
            <a className='p-4 flex justify-between items-center w-full text-gray-300' href="https://github.com/KassimBouzoubaa" target="_blank">
              Github <FaGithub size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
            <a className='p-4 flex justify-between items-center w-full text-gray-300' href="mailto:kassimbouzoubaa05@gmail.com" target="_blank">
              Email <HiOutlineMail size={30} />
            </a>
          </li>
        </ul>
      </div>

    </div>
  )
}

export default Navbar