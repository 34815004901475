import React from "react";
import { data } from "../data/data.js";

const Work = () => {
  const project = data;

  return (
    <div name='work' className='w-full text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
            Mes projets
          </p>
          <p className='py-6'>// Vous trouverez dans cette section mes dernières réalisations</p>
        </div>

        {/* container for projects */}
        <div className='grid sm:grid-cols-2 md:grid-cols-2 h-[100%] gap-12'>
          {/* Gird Item */}
          {project.map((item, index) => (
            <div key={index} className="py-3 text-center text-md ">
            <div
              
              style={{ backgroundImage: `url(${item.image})`   ,backgroundSize: 'cover' 
            }}
              className=' shadow-lg shadow-[#040c16] group container rounded-md 
              flex justify-center text-center items-center mx-auto content-div mb-7  '
            >
              {/* Hover effect for images */}
              <div className='opacity-0 group-hover:opacity-100 '>
                <span className='text-2xl font bold text-white tracking-wider '>
                  {item.name}
                </span>
                <div className='pt-8 text-center '>
                  {/* eslint-disable-next-line */}
                  <a href={item.github} target='_blank'>
                    <button
                      className='text-center rounded-lg px-4 py-3 m-2
                       bg-white text-gray-700 font-bold text-lg'
                    >
                      Voir le code
                    </button>
                  </a>
                  {/* eslint-disable-next-line */}
                  <a href={item.live} target='_blank'>
                    <button
                      className='text-center rounded-lg px-4 py-3 m-2
                       bg-white text-gray-700 font-bold text-lg'
                    >
                      Voir le site
                    </button>
                  </a>
                </div>
              </div>
            </div>
                    {item.description}
                    </div>
            
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
