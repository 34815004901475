import Krypto from '../assets/projects/krypto.png'
import Metaverse from "../assets/projects/metaverse.png"
import Netflix from "../assets/projects/netflix.png"
import TopEats from "../assets/projects/topeats.png"
import Tutor from "../assets/projects/tutor.png"
import Voting from "../assets/projects/voting.png"
import Greenfuning from "../assets/projects/greenfunding.png"


export const data=[
    {
        id:1,
        name:"Greenfunding",
        image:Greenfuning,
        github:"https://github.com/KassimBouzoubaa/greenfunding",
        live:"https://greenfunding.vercel.app/",
        description:"GreenFunding est une application décentralisée (DApp) visant à collecter des fonds pour des projets sociaux et environnementaux tirant parti des technologies Web3."
    },
    {
        id:2,
        name:"Voting DAPP",
        image:Voting,
        github:"https://github.com/romainjacquel/tp-Dapp",
        live:"https://tp-dapp.vercel.app/",
        description:"Cette DApp permet à ses utilisateurs de créer des propositions et de voter pour celles-ci de manière décentralisée."
    },
    {
        id:3,
        name:"Krypto",
        image:Krypto,
        github:"https://github.com/KassimBouzoubaa/Krypto",
        live:"https://krypto-five.vercel.app/",
        description:"Cette application offre la possibilité d'envoyer des ethers sur le réseau de test ethereum (goerli) tout en affichant les dernières transactions en temps réel."
    },
    {
        id:4,
        name:"Crypto Tutor",
        image:Tutor,
        github:"https://github.com/KassimBouzoubaa/crypto_tutor",
        live:"https://crypto-tutor.vercel.app/",
        description:"Crypto Tutor est un blog éducatif sur la blockchain, rapide et interactif, développé avec Next.js, React, Tailwind CSS, GraphQL et GraphCMS pour offrir un contenu informatif et une expérience utilisateur optimale."
    },
    {
        id:5,
        name:"Metaverse",
        image:Metaverse,
        github:"https://github.com/KassimBouzoubaa/Metaverse",
        live:"https://metaverse-sable.vercel.app/",
        description:"La landing page Metaverse explore le concept du Metaverse. Réalisée avec Next JS, React, et le framework Motion pour les animations, cette application intègre également Tailwind CSS pour le design."
    },
    {
        id:6,
        name:"Netflix Clone",
        image:Netflix,
        github:"https://github.com/KassimBouzoubaa/Netflix_Clone",
        live:"https://netflix-clone-five-lilac.vercel.app/",
        description:"Ce projet représente une réplique de Netflix, développée en utilisant React et Tailwind CSS pour concevoir l'interface visuelle. J'intègre l'API TMDB pour récupérer et afficher toutes les données nécessaires. De plus, j'ai implémenté un système d'authentification grâce à FireBase."
    },
    {
        id:7,
        name:"Uber Eats Clone",
        image:TopEats,
        github:"https://github.com/KassimBouzoubaa/topeats",
        live:"https://topeats.vercel.app/",
        description:"Cette reproduction du site UberEats est élaborée avec React JS et Tailwind CSS. Le site est conçu pour être réactif et adaptable, utilisant des mises en page flexibles et une grille responsive. Toutes les données et images sont extraites du fichier data.js pour simuler une réponse API."
    },


]